import { React } from "react";
const Reporte1 = ({ nombre, mdstatus, eseditable }) => {
    return(
        <div className=" w-full h-full">
        <iframe className=" w-full h-full border-0"
          src="https://lookerstudio.google.com/embed/reporting/0a923dc4-d917-44ad-8918-ccc2cff49781/page/GHRBE" 
      
        allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe></div>
    )
}
export default Reporte1;