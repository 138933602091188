import * as React from "react";
import Login from "./auth/Login";
import { auth } from "./data/fire";
import { onAuthStateChanged } from "firebase/auth";
import { HashRouter, Route, Routes } from "react-router-dom";
// Auth
import Layout from "./layouts/Layout";
import LayoutSplash from "./layouts/LayoutSplash";

//Escritorio
import Splash2 from "./paginas/Admin/Esc/Splash2";
// Error404
import Error404 from "./Error404";
// Siniestralidad
import Siniestralidad from "./paginas/Admin/Sin/Siniestralidad";
import Reporte1 from "./paginas/Admin/Sin/Reporte1"
import Reporte2 from "./paginas/Admin/Sin/Reporte2"
import Modulosfrm from "./paginas/Admin/Adm/Modulosfrm";

// Configuracion

function App() {
  const [permisos, setPermisos] = React.useState([]);
  const [usuario, setUsuario] = React.useState(null);
  const [authState, setAuthState] = React.useState(null);
  const [autusuario, setAutUsuario] = React.useState([]);
  const perfil = [
    {
      email: "",
    },
  ];
  React.useEffect(() => {
    // consulta si existe algun  nuevo  usuario 

    // actualiza la tabla de usuarios

    //
    const unSuscribirAuth = onAuthStateChanged(
      auth,
      async (authenticatedUser) => {
        if (authenticatedUser) {
          setAutUsuario(authenticatedUser);
          setUsuario(authenticatedUser);
          perfil.email = authenticatedUser.email;
          setAuthState("Dashboard");
        } else {
          setUsuario(null);
          setAuthState("login");
        }
      }
    );

    return unSuscribirAuth;
  }, [usuario]);

  if (authState === null)
    return (
      <div className="font-bold text-center text-5xl py-[15vh]">
        Cargando...
      </div>
    );
  if (authState === "login")
    return <Login setAuthState={setAuthState} autusuario={autusuario} />;
  let nick = 'ninguno'
  switch (autusuario.email) {
    //Admin 
    case 'paola.perez@serviciosgob.mx':
      nick = 'admin'
      break;
    case 'carlos.arreola@fyfasesores.mx':
      nick = 'admin'
      break;
    case 'mauricio.valdez@sinestry.com':
      nick = 'admin'
      break;
    // DIRECTOR GNP
    case 'director@gnp.sinestryapp.com':
      nick = 'directorgnp'
      break;
    case 'miguelangel.aguilar@gnp.com.mx':
      nick = 'directorgnp'
      break;
    case 'reservas@gnp-capufe.sinestryapp.com':
      nick = 'directorgnp'
      break;
    // COORDINADOR GNP
    case 'rafael.magdaleno@gnp.com.mx':
      nick = 'coordinadorgnp'
      break;
    case 'operador@gnp.sinestryapp.com':
      nick = 'coordinadorgnp'
      break;
    case 'ana.platon@gnp.com.mx':
      nick = 'coordinadorgnp'
      break;
    // GERENTES GNP
    case 'innovacion@sinestry.com':
      nick = 'gerentegnp'
      break;
    case 'paola.perez@sinestry.com':
      nick = 'gerentegnp'
      break;
    case 'mauricio.valdez@serviciosgob.mx':
      nick = 'gerentegnp'
      break;
    case 'gerente@gnp.sinestryapp.com':
      nick = 'gerentegnp'
      break;
    case 'diana.lara@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    case 'emma.ley@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    // DIRECTORES SINESTRY
    case 'director@sinestryapp.com':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@fyfasesores.mx':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@fyfasesores.mx':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@fyfasesores':
      nick = 'directorsinestry'
      break;
    // OPERATIVO SINESTRY
    case 'operador@sinestryapp.com':
      nick = 'operativosinestry'
      break;
    case 'carina.luna@sinestry.com':
      nick = 'operativosinestry'
      break;
    case 'manuel.mendoza@sinestry.com':
      nick = 'operativosinestry'
      break;
    case 'miriam.zuarez@sinestry.com':
      nick = 'operativosinestry'
      break;
    case 'david.sotelo@serviciosgob.mx':
      nick = 'operativosinestry'
      break;
    // GERENTE SINESTRY
    case 'yessenia.luna@sinestry.com':
      nick = 'coordinadorsinestry'
      break;
    case 'yessenia.luna@serviciosgob.mx':
      nick = 'coordinadorsinestry'
      break;
    case 'hector.trujillo@serviciosgob.mx':
      nick = 'coordinadorsinestry'
      break;
    // ninguno
    default:
      nick = 'ninguno'
      break;
  }

  if (usuario)
    return (
      <div>
        <HashRouter>
          <Routes>
            <Route>
              <Route path="/" element={<LayoutSplash autusuario={autusuario} />}>
                <Route index elemnt={<Splash2 autusuario={autusuario} />} />
              </Route>
              <Route path="/" element={<Layout autusuario={autusuario} />}>
                <Route index elemnt={<Splash2 autusuario={autusuario} />} />
                {/* Siniestralidad */}
                <Route path="sin/reporte1" element={<Reporte1 />} />
                <Route path="sin/reporte2" element={<Reporte2 />} />
                <Route path="sin/siniestralidad" element={<Siniestralidad />} />          
            
              </Route>
            </Route>
            {/* Errores */}
            <Route path="*" element={<Error404 />}></Route>
          </Routes>
        </HashRouter>
      </div>
    );
}

export default App;
