import { Link } from "react-router-dom";
import logosplash from "../../../auth/Login/logosplash.png";
import { React, useState } from "react";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { PiNotepadBold } from "react-icons/pi";
import { IoLogoWhatsapp } from "react-icons/io";
import rolesPermisos from '../../../data/rolesPermisos'
import { FaChartPie } from "react-icons/fa";

const Splash2 = ({ autusuario }) => {
  const uid = autusuario.uid;
  //const nick=autusuario.uid

  const mensaje = "Innovation power, unrivalled expertise in all aspects of risk and a sound financial footing: These are the strengths Munich Re stands for when customers face extraordinary risks.";
  const [expanded, setExpanded] = useState(true);
  const [values, setValues] = useState({});
  const email = autusuario.email;
  let nick = "ninguno"
  switch (autusuario.email) {
    //Admin 
    case 'paola.perez@serviciosgob.mx':
      nick = 'admin'
      break;
    case 'carlos.arreola@fyfasesores.mx':
      nick = 'admin'
      break;
    case 'manuel.mendoza@sinestry.com':
        nick = 'admin'
        break;
    case 'mauricio.valdez@sinestry.com':
      nick = 'admin'
      break;
    // DIRECTOR GNP
    case 'director@gnp.sinestryapp.com':
      nick = 'directorgnp'
      break;
    case 'miguelangel.aguilar@gnp.com.mx':
      nick = 'directorgnp'
      break;
    case 'reservas@gnp-capufe.sinestryapp.com':
      nick = 'directorgnp'
      break;
    // COORDINADOR GNP
    case 'rafael.magdaleno@gnp.com.mx':
      nick = 'coordinadorgnp'
      break;
    case 'operador@gnp.sinestryapp.com':
      nick = 'coordinadorgnp'
      break;
    case 'ana.platon@gnp.com.mx':
      nick = 'coordinadorgnp'
      break;
    // GERENTES GNP
    case 'innovacion@sinestry.com':
      nick = 'gerentegnp'
      break;
    case 'paola.perez@sinestry.com':
      nick = 'gerentegnp'
      break;
    case 'mauricio.valdez@serviciosgob.mx':
      nick = 'gerentegnp'
      break;
    case 'gerente@gnp.sinestryapp.com':
      nick = 'gerentegnp'
      break;
    case 'diana.lara@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    case 'emma.ley@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    // DIRECTORES SINESTRY
    case 'director@sinestryapp.com':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@fyfasesores.mx':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@fyfasesores.mx':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@fyfasesores':
      nick = 'directorsinestry'
      break;
    // OPERATIVO SINESTRY
    case 'operador@sinestryapp.com':
      nick = 'operativosinestry'
      break;
    case 'carina.luna@sinestry.com':
      nick = 'operativosinestry'
      break;
    case 'manuel.mendoza@sinestry.com':
      nick = 'operativosinestry'
      break;
    case 'miriam.zuarez@sinestry.com':
      nick = 'operativosinestry'
      break;
    case 'david.sotelo@serviciosgob.mx':
      nick = 'operativosinestry'
      break;
    // GERENTE SINESTRY
    case 'yessenia.luna@sinestry.com':
      nick = 'coordinadorsinestry'
      break;
    case 'yessenia.luna@serviciosgob.mx':
      nick = 'coordinadorsinestry'
      break;
    case 'hector.trujillo@serviciosgob.mx':
      nick = 'coordinadorsinestry'
      break;
    // ninguno
    default:
      nick = 'ninguno'
      break;
  }
  // Función para actualizar los valores de forma dinámica
  const handleChange = (key, value) => {
    setExpanded(true)
    setValues(prevValues => ({
      ...prevValues,
      [key]: value
    }));
  
   
  };
  return (<>
    <div className="container  justify-center">
      <div
        className="absolute top-[10vh] w-[60%] rounded-[2.5vw] shadow-gray-400 shadow -mt-[2vh] ml-[13vw] 
             flex flex-col justify-center"
      >
        <div className="px-[20vw] pt-5">
          <img src={logosplash} alt="" />
        </div>
        <div className="w-full flex flex-row justify-center">
          <span className="text-[1.5rem] justify-center items-center font-bold text-titulo1LoginSinApp ">
            Welcome to SiniestrosApp
          </span>
        </div>
        <div className="w-full flex justify-center ">
          <div className="w-full gap-2 px-10">
            <p className="py-1 text-justify text-[.8rem] text-titulo2LoginSinApp">
              {mensaje}
           
            </p>
            <p className="py-1 text-justify"></p>
          </div>
        </div>
        <ul className="w-full pl-5 items-center grid grid-cols-3  py-[2vh] text-[1.2rem]">
          {/*  */}

          {rolesPermisos[nick].menus.map((itemmenu) => (
            <>
              <li
                onClick={(e) => handleChange(itemmenu.clave, !values[itemmenu.clave])}
                key={itemmenu.id}>
                <Link to={itemmenu.url} target={itemmenu.win === "" ? "" : itemmenu.win} 
                className={`flex flew-row justify-around z-40 
                  items-center p-1 text-[.8rem] gap-2  mb-2 bg-btnSplahSinApp h-8 col-1 mr-5 w-[18vw] text-btntSplahTxtBcoSinApp hover:text-btntSplahTxtBcoSinApp  rounded-[15vh]`}><span>{itemmenu.icono}</span>
                  <span>{itemmenu.titulo}</span>
                  <span className="text-[.8rem] px-4 pt-2 block float-left">{values[itemmenu.clave] ? <FaChevronUp /> : <FaChevronDown />}</span>
                </Link> 

                <div className="fixed  grid grid-cols-3 left-[18vw] top-[39vh] w-[80vw] h-auto bg-gray-200 rounded-md"> {itemmenu.submenu && values[itemmenu.clave] && (
                  <>
                  <ul className={`flex justify-between items-start p-3 h-[55vh] ${values[itemmenu.clave] ? '' : 'hidden'}`}>
                    {itemmenu.submenus.map((subitem) => (
                      <>
                        <li
                          //  onClick={()=>{setSubmenuOpen1(!submenuOpen1)}}
                          onClick={(e) => handleChange(subitem.clave, !values[subitem.clave])}
                          key={subitem.id}                                                   

                        > <Link to={subitem.url} target={subitem.win === "" ? "" : subitem.win}
                        className={`flex flex-row justify-around z-40 items-center p-1 text-[.8rem] 
                           gap-x-2 mb-2 bg-btnSplahSinApp h-8 col-1 mr-5 w-[25vw] text-btntSplahTxtBcoSinApp hover:text-btntSplahTxtBcoSinApp rounded-[15vh]`}
                        >
                            <span
                              className={`text-[1.1rem] ${subitem.spaces ? "pl-6" : "pl-2"} pl-4 pt-2 block float-left`}
                            >
                              {subitem.icono}
                            </span>
                            <span
                              className={` text-[.8rem] pt-2 font-semibold flex-1 `}
                            >
                              {subitem.titulo}
                            </span>
                          </Link>

                          <ul className={`flex flex-col justify-between items-left p-3`}>
                          {subitem.submenus.map((subitem1) => (
                            <Link to={subitem1.url} target={subitem1.win === "" ? "" : subitem1.win}>
                            <li className="flex flew-row w-full gap-2 justify-start items-left text-btntSplahTxtSinApp  text-[.8rem]"><span>{itemmenu.icono}</span>{subitem1.titulo}</li></Link>))}</ul>
 </li>
                      </>
                    ))}
                  </ul></>)}  
                  </div>
                           </li> 
           
            </>
          ))}
        </ul>

      </div>

    </div>

  </>);
};
export default Splash2;
