
import { PiNotepadBold } from "react-icons/pi";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaChartPie } from "react-icons/fa";
import { PiStudent } from "react-icons/pi";
import { GrDocumentConfig } from "react-icons/gr";
import { FaRoute } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { MdViewModule } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import { MdOutlineRequestQuote } from "react-icons/md";
import { GiProfit } from "react-icons/gi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";

const rolesPermisos = {
  ninguno: {
    menus: [{
      id: 1,
      spaces: true,
      titulo: "Whatsapp",
      clave: "whatsap",
      url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
      win: "_blank",
      icono: <IoLogoWhatsapp />,
      submenu: false,
      submenus: [],
    },]
  },
  /* Admin */
  admin: {
    menus: [ 
            // Siniestrlidad
            {
              id: 1,
              spaces: false,
              titulo: "Siniestralidad",
              clave: "siniestralidad",
              url: "/sin/siniestralidad",
              win: "",
              icono: <PiNotepadBold />,
              submenu: false,
              submenus:[] 
            },
            // Estadisticas
            {
              id: 5,
              spaces: true,
              titulo: "Estadísticas",
              clave: "estadisticas",
              url: "sin/reporte1",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },   
      //Configuracion
      {
        id: 5,
        spaces: false,
        titulo: "Configuración",
        clave: "configuracion",
        url: "/",
        win: "",
        icono: <GrDocumentConfig />,
        submenu: true,
        submenus: [{
          id:6,
          spaces: true,
          titulo: "Usuarios",
          clave: "usuarios",
          url: "/cnf/usuarios",
          win: "",
          icono: <FaRegUser />,
          submenu: false,
          submenus: [],
        }, {
          id: 7,
          spaces: true,
          titulo: "Modulos",
          clave: "modulos",
          url: "/cnf/modulos",
          win: "",
          icono: <MdViewModule />,
          submenu: false,
          submenus: [],
        }, {
          id: 8,
          spaces: true,
          titulo: "Permisos",
          clave: "permisos",
          url: "/cnf/permisoss",
          win: "",
          icono: <FaUserCheck />,
          submenu: false,
          submenus: [],
        },],
      },

    ],
  },
  /*       */
  directorsinestry: {
    menus: [
      // Siniestrlidad
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "sin/siniestralidad",
        win: "",
        icono: <PiNotepadBold />,
        submenu: false,
        submenus:[] 
      },
      // Estadisticas
      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "sin/reporte1",
        win: "",
        icono: <FaChartPie />,
        submenu: false,
        submenus: [],
      },
    
 
    ],
  },
  /*       */
  coordinadosinestry: {
    menus: [
      // Siniestrlidad
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "sin/siniestralidad",
        win: "",
        icono: <PiNotepadBold />,
        submenu: false,
        submenus:[] 
      },
      // Estadisticas
      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "sin/reporte1",
        win: "",
        icono: <FaChartPie />,
        submenu: false,
        submenus: [],
      },
    
      //Whatsapp

    ],
  },
  /*       */
  operativosinestry:{
    menus: [
      // Siniestrlidad
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "sin/siniestralidad",
        win: "",
        icono: <PiNotepadBold />,
        submenu: false,
        submenus:[] 
      },
      // Estadisticas
      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "sin/reporte1",
        win: "",
        icono: <FaChartPie />,
        submenu: false,
        submenus: [],
      },
    
      //Whatsapp
      {
        id: 99,
        spaces: true,
        titulo: "Whatsapp",
        clave: "whatsap",
        url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
        win: "_blank",
        icono: <IoLogoWhatsapp />,
        submenu: false,
        submenus: [],
      },
    ],
  },
  /* gnp   */
  gerentegnp: {
    menus: [
      // Siniestrlidad
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "sin/siniestralidad",
        win: "",
        icono: <PiNotepadBold />,
        submenu: false,
        submenus:[] 
      },
      // Estadisticas
      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "sin/reporte1",
        win: "",
        icono: <FaChartPie />,
        submenu: false,
        submenus: [],
      },
    

    ],
  },
  /*       */
  coordinadorgnp:{
    menus: [
      // Siniestrlidad
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "sin/siniestralidad",
        win: "",
        icono: <PiNotepadBold />,
        submenu: false,
        submenus:[] 
      },
      // Estadisticas
      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "sin/reporte1",
        win: "",
        icono: <FaChartPie />,
        submenu: false,
        submenus: [],
      },
    
     
    ],
  },
  /*       */
  directorgnp:{
    menus: [
      // Siniestrlidad
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "sin/siniestralidad",
        win: "",
        icono: <PiNotepadBold />,
        submenu: false,
        submenus:[] 
      },
      // Estadisticas
      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "",
        win: "",
        icono: <FaChartPie />,
        submenu: false,
        submenus: [ {
          id: 6,
          spaces: true,
          titulo: "Estadísticas",
          clave: "estadisticas2",
          url: "sin/reporte1",
          win: "",
          icono: <FaChartPie />,
          submenu: false,
          submenus: [],
        },{
          id: 6,
          spaces: true,
          titulo: "Estadísticas 2",
          clave: "estadisticas 2",
          url: "sin/reporte2",
          win: "",
          icono: <FaChartPie />,
          submenu: false,
          submenus: [],
        },],
      },
       // Estadisticas
      
    
     
    ],
  },
};
export default rolesPermisos
