import { React} from "react";
import Sidebar2 from "../components/Sidebar2";
import { Outlet } from "react-router-dom";
const Layout = ({autusuario,nick}) => {
  const authuser="";
  return (
    <div className="flex">   
      <Sidebar2 autusuario={autusuario}  nick={nick}/>
      <div className="w-full  text-grisSinestry font-semibold">
      <Outlet /> 
      </div>
    </div>
  );
};
export default Layout;
